$(function() {
  tieVisibilityToCheckbox('#js-medicaid-info', "[name='insurance[has_medicaid]']", "true")
  tieVisibilityToCheckbox('#js-insurance-info', "[name='insurance[has_insurance]']", "true")
  tieVisibilityToCheckbox('#js-income-household-member', "[name='income_source[source_type]']", "household_member_income_source")
  tieVisibilityToCheckbox('#js-income-other', "[name='income_source[source_type]']", "other_income_source")
  tieVisibilityToCheckbox('#js-unemployed', "[name='employment[employment_type]']", "unemployed")
  tieVisibilityToCheckbox('#js-self-employed', "[name='employment[employment_type]']", "self_employed")

  function tieVisibilityToCheckbox(target, radio, value) {
    $(radio).on('change', function() { toggleVisibility(target, radio, value) })
    toggleVisibility(target, radio, value)
  }

  function toggleVisibility(target, radio, value) {
    if($(`${radio}:checked`).val() === value) {
      $(target).removeClass('hidden');
    } else {
      $(target).find('input:not([type=hidden])').val('');
      $(target).find('textarea:not([type=hidden])').val('');
      $(target).addClass('hidden');
    }
  }

  if(window.location.pathname !== "/") {
    var timeout = startTimer()
    $("body").on('keypress click', function() {
      clearTimeout(timeout);
      timeout = startTimer();
    })
  };

  function startTimer() {
    return setTimeout(timeoutWarning, 5 * 60 * 1000);
  }

  function timeoutWarning() {
    $("#dialog").show()
    setTimeout(noActivity, 60 * 1000);
  }

  function noActivity() {
    window.location.href = "/";
  }

  $("#exit-dialog").on("click", function() {
    $("#dialog").hide();
  });

  $(".start-another").on("click", function() {
    $crisp.push(["o", "session:reset"]);
  });

  $('.mobile-nav-controller').on('click', function() {
    // this is probably not on the DOM, find a generic implementation of slide in slide out
    if($("#mobile-nav-overlay").is(':hidden')) {
      $("#mobile-nav-overlay, #mobile-nav-outer").removeClass('hidden')
      setTimeout(function() {
        $("#mobile-nav-overlay").removeClass('opacity-0').addClass('opacity-100')
        $("#mobile-nav").removeClass('-translate-x-full').addClass('translate-x-0')
      }, 100);
    } else {
      $("#mobile-nav-overlay").removeClass('opacity-100').addClass('opacity-0')
      $("#mobile-nav").removeClass('translate-x-0').addClass('-translate-x-full')
      setTimeout(function() {
        $("#mobile-nav-overlay, #mobile-nav-outer").addClass('hidden')
      }, 300);
    }
  })
});
